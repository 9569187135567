import {Btn} from './Btn'

type Props = {
    children: string | JSX.Element | JSX.Element[]
    color: string,
    className?: string
    onClick: () => void
  }
export const Tag = ({
    children,
    onClick,
    color,
    className
}: Props) => {
    return (
    <Btn
      color={color}
      className={`tag ${className}`}
      style={{
        borderRadius: '25px',
        padding: '4px 12px'
      }}
      weight="medium"
      onClick={onClick}
    >{children}</Btn>)
}

