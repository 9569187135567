import { useState, ReactNode } from 'react'
// import { IconArrowDown } from '../../icons/IconArrowDown'
import { Text } from '../Text'
import { Icon } from '../Icon'
interface AccordionProps {
    children: ReactNode[]
}

export const Accordion = ({ children }: AccordionProps ) => {
    return (
        <div>
            { children }
        </div>
    )
}

interface AccordionItemProps {
    title: string
    subtitle: string | undefined,
    children: ReactNode,
    classNameWrapper?: string | undefined,
    classNameHeader?: string | undefined,
    classNameContent?: string | undefined
}

export const AccordionItem = ({ title, subtitle, children, classNameHeader, classNameContent, classNameWrapper }: AccordionItemProps) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={classNameWrapper}>
            <div
                className={classNameHeader}
                onClick={toggleOpen}
            >
                <div>
                    <Text size="sm" weight="bold" tag="div">
                        { title}
                    </Text>
                    <Text size="sm" tag="div" className="mt-1">
                        { subtitle }
                    </Text>
                </div>
                <Icon name="icon-arrow-down" style={{ cursor: 'pointer', transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }} />
            </div>
            {isOpen && (
                <div className={classNameContent}>
                    {children}
                </div>
            )}
        </div>
    )
}